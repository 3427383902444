import Daily, {
  DailyEventObjectInputSettingsUpdated,
  DailyInputAudioProcessorSettings,
  DailyInputSettings,
} from '@daily-co/daily-js';
import { useInputSettings } from '@daily-co/daily-react';
import deepEqual from 'fast-deep-equal';
import { atom, useAtomValue } from 'jotai';
import { atomWithStorage, useAtomCallback } from 'jotai/utils';
import Cookies from 'js-cookie';
import { useCallback, useRef } from 'react';

import { useCallConfig } from './useCallConfig';

type AudioProcessor = DailyInputAudioProcessorSettings['type'];
const audioProcessors: AudioProcessor[] = ['none', 'noise-cancellation'];
const COOKIE_KEY_DEPRECATED = 'audio-processor';
const COOKIE_KEY = `__Host-${COOKIE_KEY_DEPRECATED}`;

const cookieStorage = {
  getItem: (key: string): AudioProcessor | null => {
    if (!Daily.supportedBrowser().supportsAudioProcessing) return null;
    let storedProcessor = Cookies.get(key) as AudioProcessor;
    if (audioProcessors.includes(storedProcessor)) return storedProcessor;
    storedProcessor = Cookies.get(COOKIE_KEY_DEPRECATED) as AudioProcessor;
    if (audioProcessors.includes(storedProcessor)) {
      Cookies.remove(COOKIE_KEY_DEPRECATED, { sameSite: 'none', secure: true });
      Cookies.set(key, storedProcessor, {
        expires: new Date('Tue, 19 Jan 2038 03:14:07 GMT'),
        sameSite: 'none',
        secure: true,
        partitioned: true,
      });
      return storedProcessor;
    }
    return null;
  },
  setItem: (key: string, newValue: AudioProcessor) => {
    if (newValue === 'none') {
      Cookies.remove(key, {
        sameSite: 'none',
        secure: true,
        partitioned: true,
      });
    } else {
      Cookies.set(key, newValue, {
        expires: new Date('Tue, 19 Jan 2038 03:14:07 GMT'),
        sameSite: 'none',
        secure: true,
        partitioned: true,
      });
    }
  },
  removeItem: (key: string) => {
    Cookies.remove(key, { sameSite: 'none', secure: true, partitioned: true });
  },
};

const selectedAudioProcessorAtom = atomWithStorage<AudioProcessor>(
  COOKIE_KEY,
  'none',
  cookieStorage
);

const noiseCancellationDisabledAtom = atom(false);

export const useAudioProcessor = () => {
  const { enableNoiseCancellationUI } = useCallConfig();
  const waitForUpdate = useRef<boolean>(false);
  const noiseCancellationDisabled = useAtomValue(noiseCancellationDisabledAtom);
  const currentAudioProcessor = useAtomValue(selectedAudioProcessorAtom);

  /* Update the atom state when the input settings change */
  const { inputSettings, updateInputSettings } = useInputSettings({
    onInputSettingsUpdated: useAtomCallback(
      useCallback((_get, set, event: DailyEventObjectInputSettingsUpdated) => {
        if (!event.inputSettings?.audio) return;

        const type = event.inputSettings?.audio?.processor?.type;

        switch (type) {
          case 'noise-cancellation':
            set(selectedAudioProcessorAtom, 'noise-cancellation');
            break;
          case 'none':
            set(selectedAudioProcessorAtom, 'none');
            break;
        }

        if (waitForUpdate.current) {
          waitForUpdate.current = false;
          set(noiseCancellationDisabledAtom, false);
        }
      }, [])
    ),
  });

  const audioProcessorType = inputSettings?.audio?.processor?.type;

  /* Update the input settings */
  const setAudioProcessor = useCallback(
    (audioProcessor: AudioProcessor) => {
      if (!enableNoiseCancellationUI) return;

      try {
        const settings: DailyInputSettings = {
          audio: {
            processor: {
              type: audioProcessor,
            },
          },
        };

        // old and new settings the same, so return
        if (deepEqual(inputSettings?.audio, settings?.audio)) {
          return;
        }

        //  the current input settings is an empty object, and the new input settings
        //  do not actually change anything, so no-op
        if (deepEqual(inputSettings, {}) && audioProcessor === 'none') {
          return;
        }

        updateInputSettings(settings);
      } catch {}
    },
    [enableNoiseCancellationUI, inputSettings, updateInputSettings]
  );

  const handleNoiseCancellationChange = useAtomCallback(
    useCallback(
      (_get, set, isOn: boolean) => {
        set(noiseCancellationDisabledAtom, true);
        waitForUpdate.current = true;
        setAudioProcessor(isOn ? 'noise-cancellation' : 'none');
      },
      [setAudioProcessor]
    )
  );

  return {
    noiseCancellationOn: audioProcessorType === 'noise-cancellation',
    noiseCancellationDisabled,
    handleNoiseCancellationChange,
    currentAudioProcessor,
    setAudioProcessor,
  };
};
