import '/lib/i18n';
import '/lib/sentry';

import { SnackbarProvider } from '@daily/shared/contexts/Snackbar';
import { ThemeProvider } from '@daily/shared/contexts/Theme';
import { safeStorage } from '@daily/shared/lib/safeStorage';
import * as Sentry from '@sentry/browser';
import { Provider as JotaiProvider } from 'jotai';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { App } from '/components/App';
import { FinalMessage } from '/components/App/FinalMessage';
import { Layout } from '/components/App/Layout';
import { LoadingScreen } from '/components/App/LoadingScreen';
import { AutoChunkLoader } from '/components/ChunkLoader';
import CustomAppError from '/components/CustomAppError';
import { CallProvider } from '/contexts/CallProvider';
import { ConditionalProvider } from '/contexts/ConditionalProvider';
import { IframeDriverProvider } from '/contexts/IframeDriverProvider';
import { MediaDeviceProvider } from '/contexts/MediaDeviceProvider';
import { ParticipantsProvider } from '/contexts/ParticipantsProvider';
import { TracksProvider } from '/contexts/TracksProvider';
import { useIframeLauncher } from '/hooks/iframe/useIframeLauncher';
import { getQueryParam } from '/lib/query';
import { captureInvalidStateError } from '/lib/sentry-capture-helpers';

const BreakoutSessionStateProvider = dynamic(
  () =>
    import('/features/breakouts/contexts/BreakoutSessionStateProvider').then(
      (mod) => mod.BreakoutSessionStateProvider
    ),
  {
    loading: AutoChunkLoader,
  }
);
const ChatProvider = dynamic(
  () =>
    import('/features/chat/contexts/ChatProvider').then(
      (mod) => mod.ChatProvider
    ),
  {
    loading: AutoChunkLoader,
  }
);
const RecordingProvider = dynamic(
  () =>
    import('/features/recording/contexts/RecordingProvider').then(
      (mod) => mod.RecordingProvider
    ),
  {
    loading: AutoChunkLoader,
  }
);

export default function CallUI() {
  const { t } = useTranslation();
  const { query, replace } = useRouter();
  const [domain, setDomain] = useState(null);
  const [isDomainSet, setIsDomainSet] = useState(true);
  const [customHost, setCustomHost] = useState(null);
  const [bypassRegionDetection, setBypassRegionDetection] = useState(false);
  const [v2CamAndMic, setV2CamAndMic] = useState<boolean | number | null>(null);
  const [disableRecordingIndicator, setDisableRecordingIndicator] =
    useState<boolean>(false);

  const [useLegacyVideoProcessor, setUseLegacyVideoProcessor] =
    useState<boolean>(false);
  const [roomsCheckOrigin, setRoomsCheckOrigin] = useState<string>(null);
  const [apiHost, setApiHost] = useState<string>(null);
  const [room, setRoom] = useState(null);
  const [token, setToken] = useState('');
  const [frameId, setFrameId] = useState('');
  const [embeddingPageURL, setEmbeddingPageURL] = useState<URL>(null);

  /**
   * Read all required query params, once query is ready,
   * making sure they'll never be removed from state,
   * when they're removed from the URL (like token).
   */
  useEffect(() => {
    if (Object.keys(query).length === 0) return;
    if (query.debug) {
      console.log({
        app_name: 'prebuilt',
        git_hash: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
        git_ref: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF,
        environment_name:
          process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT ??
          (location.hostname.includes('localhost') ||
          location.hostname.includes('khk-local.wss.daily.co')
            ? 'local-development'
            : process.env.NODE_ENV),
      });
    }
    if (query.name) {
      // HACK: Set PLUOT_PARTICIPANT_NAME in localStorage directly,
      // so daily-js will pick that up immediately.
      // This has the advantage of not having to wait until daily-js is loaded.
      const safeLocalStorage = safeStorage(() => localStorage);
      safeLocalStorage.setItem(
        'PLUOT_PARTICIPANT_NAME',
        getQueryParam('name', query)
      );
    }
    if (query.customHost) {
      setCustomHost(getQueryParam('customHost', query));
    } else if (process.env.DAILY_CUSTOM_HOST) {
      setCustomHost(process.env.DAILY_CUSTOM_HOST);
    }
    if (query.bypassRegionDetection)
      setBypassRegionDetection(
        getQueryParam('bypassRegionDetection', query) === 'true'
      );
    if (query.v2CamAndMic) {
      const v2CamAndMicParam = getQueryParam('v2CamAndMic', query);
      const v2CamAndMicParamAsNumber = parseFloat(v2CamAndMicParam);
      setV2CamAndMic(
        isNaN(v2CamAndMicParamAsNumber)
          ? v2CamAndMicParam === 'true'
          : v2CamAndMicParamAsNumber
      );
    }
    if (query.disableRecordingIndicator) {
      const param = getQueryParam('disableRecordingIndicator', query);
      setDisableRecordingIndicator(param === 'true');
    }
    if (query.useLegacyVideoProcessor) {
      const param = getQueryParam('useLegacyVideoProcessor', query);
      if (param === 'true') {
        setUseLegacyVideoProcessor(true);
      }
    }
    if (query.roomsCheckOrigin) {
      setRoomsCheckOrigin(getQueryParam('roomsCheckOrigin', query));
    } else if (process.env.DAILY_ROOMS_CHECK_ORIGIN) {
      setRoomsCheckOrigin(process.env.DAILY_ROOMS_CHECK_ORIGIN);
    }
    if (query.apiHost) {
      setApiHost(getQueryParam('apiHost', query));
    } else if (process.env.DAILY_API_HOST) {
      setApiHost(process.env.DAILY_API_HOST);
    }
    if (query.t) {
      setToken(getQueryParam('t', query));
    }
    if (query.emb) {
      setFrameId(getQueryParam('emb', query));
    }
    if (query.embHref) {
      const embHref = getQueryParam('embHref', query);
      // Note: here we assume a valid embHref (else something's seriously wrong)
      setEmbeddingPageURL(new URL(embHref));
    }
    if (query.room) {
      if (Array.isArray(query.room)) {
        const l = query.room.length;
        const r = query.room?.[l - 1];
        const d = query.room?.[l - 2];
        setRoom(r);
        if (d?.match(/.+\.daily\.co$/)) {
          setDomain(d.replace('.daily.co', ''));
          setIsDomainSet(true);
          return;
        }
      } else {
        setRoom(query.room);
      }
    }
    if (query.domain) {
      setDomain(getQueryParam('domain', query));
      setIsDomainSet(true);
      return;
    }
    if (process.env.NEXT_PUBLIC_DOMAIN_NAME) {
      setDomain(process.env.NEXT_PUBLIC_DOMAIN_NAME);
      setIsDomainSet(true);
      return;
    }
    // Find domain via location.hostname
    let dnsTrailer = '.daily.co';
    if (process.env.DAILY_DNS_TRAILER) {
      dnsTrailer = process.env.DAILY_DNS_TRAILER;
    }
    if (location.hostname.endsWith(dnsTrailer)) {
      setDomain(location.hostname.replace(dnsTrailer, ''));
      setIsDomainSet(true);
      return;
    }
    setIsDomainSet(false);
  }, [query]);

  /**
   * Once we got the token, remove it from the URL.
   */
  useEffect(() => {
    if (!token || !query.t) return;
    const { t: _t, ...newQuery } = query;
    replace(
      {
        query: newQuery,
      },
      undefined,
      { shallow: true }
    );
  }, [query, replace, token]);

  // Log InvalidStateError with as many details as possible for further investigation
  useEffect(function logInvalidStateError() {
    const oldOnError = window.onerror;
    window.onerror = (msg, url, line, column, error) => {
      console.error(msg, url, line, column, error);

      if (oldOnError) {
        oldOnError.apply(this, [msg, url, line, column, error]);
      }

      if (error.name === 'InvalidStateError') {
        captureInvalidStateError(error, {});
      }

      return false;
    };
    return () => {
      window.onerror = oldOnError;
    };
  }, []);

  const isEmbedded = !!frameId;

  const { darkColors, launchConfig, lightColors } = useIframeLauncher();

  if (isEmbedded && !launchConfig) {
    /**
     * Keeps the content transparent and hidden, until launch config is clear.
     */
    return (
      <style global jsx>{`
        html,
        body {
          background: transparent;
          visibility: hidden;
        }
      `}</style>
    );
  }

  if (!isDomainSet) {
    return (
      <ThemeProvider colors={lightColors} darkColors={darkColors}>
        <Layout>
          <FinalMessage
            title={t('lobby.notFound.title')}
            desc={t('lobby.notFound.desc')}
            variant="error"
          />
        </Layout>
      </ThemeProvider>
    );
  }

  if (!domain) {
    return (
      <ThemeProvider colors={lightColors} darkColors={darkColors}>
        <LoadingScreen />
      </ThemeProvider>
    );
  }

  const logAppError = (error: Error) => {
    // override the sampling rate via startTransaction(), since we always want to send client-side app errors to Sentry
    const transaction = Sentry.startTransaction({
      name: 'app-error',
      op: 'logAppError',
      sampled: true,
    });
    Sentry.captureException(error);
    transaction?.finish();
  };

  return (
    <ErrorBoundary onError={logAppError} FallbackComponent={CustomAppError}>
      <ThemeProvider colors={lightColors} darkColors={darkColors}>
        <JotaiProvider>
          <SnackbarProvider>
            <CallProvider
              domain={domain}
              room={room}
              customHost={customHost}
              token={token}
              isEmbedded={isEmbedded}
              bypassRegionDetection={bypassRegionDetection}
              v2CamAndMic={v2CamAndMic}
              useLegacyVideoProcessor={useLegacyVideoProcessor}
              disableRecordingIndicator={disableRecordingIndicator}
              roomsCheckOrigin={roomsCheckOrigin}
              apiHost={apiHost}
              micAudioMode={launchConfig?.micAudioMode}
            >
              <ParticipantsProvider>
                <TracksProvider>
                  <IframeDriverProvider
                    frameId={frameId}
                    embeddingPageURL={embeddingPageURL}
                  >
                    <MediaDeviceProvider>
                      <ConditionalProvider
                        check={(config) => Boolean(config.enableRecording)}
                        Provider={RecordingProvider}
                      >
                        <ConditionalProvider
                          check={(config) =>
                            Boolean(config.enableBreakoutRooms)
                          }
                          Provider={BreakoutSessionStateProvider}
                        >
                          <ConditionalProvider
                            check={(config) => Boolean(config.enableChat)}
                            Provider={ChatProvider}
                          >
                            <App />
                          </ConditionalProvider>
                        </ConditionalProvider>
                      </ConditionalProvider>
                    </MediaDeviceProvider>
                  </IframeDriverProvider>
                </TracksProvider>
              </ParticipantsProvider>
            </CallProvider>
          </SnackbarProvider>
        </JotaiProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}
